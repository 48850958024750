<template>
	<div class="friend">
		<el-card class="box-card">
		  <div slot="header" class="d-flex align-items-center">
		  	<!-- <img class="card-icon" src="../assets/lianjie.png"/> -->
		    <span>小聂公众号</span>
		    <el-button class="ml-auto" style="padding: 3px 0" type="text">
		    	<router-link to="/apply" tag="span"></router-link>
		    </el-button>
		  </div>
		  <div class="item">
        <img class="imgys" src="https://abc.xnrun.com/image/cover/E76C15431D6F41F88E852EF4B500575E-6-2.png"></div>
		</el-card>
	</div>
</template>

<script>
export default {
  name: 'friend'
}
</script>

<style scoped>
.box-card .item a{
	text-decoration: none;
	/*color: #F56C6C;*/
}
.imgys{
  width: 100%;height: auto;min-width: 100%;
}
.box-card span{
	font-weight: bold;
}
.card-icon{
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
</style>
