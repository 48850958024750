<template>
  <div class="photo_wall">
    <div v-if="windowWidth > 1200">
      <div class="photo_wall_left">
        <h1>小聂跑法训练营战队成员荣誉榜</h1>
        <div class="photo_wall_left_row" v-for="(item,index) in photoList" :key="index">
          <span>{{item.type == 'coach' ? '助理教练' : '队员'}}</span>
          <div class="photo_wall_left_row">
            <div class="photo_wall_left_row_item hover"  v-for="(item2,index2) in item.data" :key="index2"
              @mouseenter="activeCard = '' + index + index2"
              @mouseleave="activeCard = '';isclick = false">
              <img :src="item2.personalImage || 'https://ccc.xnrun.com/Wisdom_life/upfile/1606382948.png'" alt="">
              <div class="photo_wall_left_row_item_bottom" :class="[activeCard == ''+index+index2 ? 'photo_wall_left_row_item_bottom_hover' : '']">
                <el-button @click="toDetail(index,index2)">学员详情</el-button>
                <el-button @click="isclick = true">手机查看</el-button>
              </div>
              <div class="photo_wall_left_row_item_qrCode" :class="[activeCard == ''+index+index2 && isclick ? 'photo_wall_left_row_item_qrCode_hover' : '']"
                @click="isclick = false">
                <img :src="item2.codeurl" alt="">
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[1, 5, 10, 15, 20]"
          :page-size="pageSize"
          background=""
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

      <div class="photo_wall_right">
        <img src="https://ccc.xnrun.com/Wisdom_life/upfile/1606383306.png" alt="">
        <h1>主教练</h1>
        <img class="hover" src="https://ccc.xnrun.com/Wisdom_life/upfile/1606383324.png">
      </div>
    </div>

    <div v-else class="mobile">
      <h2>小聂跑法训练营战队成员荣誉榜</h2>
      <div class="items">
        <h3 class="title">主教练</h3>
        <div class="item">
          <img src="https://ccc.xnrun.com/Wisdom_life/upfile/1606383324.png">
        </div>
      </div>
      <div class="items" v-for="(item,index) in photoList" :key="index">
        <h3 class="title">{{item.type == 'coach' ? '助理教练' : '队员'}}</h3>
        <div class="item">
          <div class="photo_wall_left_row_item2"  v-for="(item2,index2) in item.data" :key="index2"
            @mouseenter="activeCard = '' + index + index2"
            @mouseleave="activeCard = '';isclick = false">
            <img :src="item2.personalImage || 'https://ccc.xnrun.com/Wisdom_life/upfile/1606382948.png'" alt="">
            <div class="photo_wall_left_row_item_bottom2" :class="[activeCard == ''+index+index2 ? 'photo_wall_left_row_item_bottom_hover2' : '']">
              <div class="button" @click="toDetail(index,index2)">学员详情</div>
              <!-- <div class="button" @click="isclick = true">手机查看</div> -->
            </div>
            <div class="photo_wall_left_row_item_qrCode2" :class="[activeCard == ''+index+index2 && isclick ? 'photo_wall_left_row_item_qrCode_hover2' : '']"
              @click="isclick = false">
              <img :src="item2.codeurl" alt="">
            </div>
          </div>
          <!-- <img class="hover" v-for="(item2,index2) in item.data" :key="index2" :src="item2.personalImage"> -->
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false">
      <PhotoWallDetail :data="curDetail"></PhotoWallDetail>
      <img v-if="windowWidth < 1200" src="https://abc.xnrun.com/image/cover/73C6980E2204455AA2EF4A2983F2813B-6-2.png" alt="">
    </el-dialog>

  </div>


</template>

<script>
import PhotoWallDetail from './PhotoWallDetail.vue'
export default{
  components:{
    PhotoWallDetail
  },
  data(){
    return{
      activeCard:'',
      isclick: false,
      dialogVisible: false,
      photoList:[],
      curDetail:'',

      // 分页
      currentPage:1,
      pageSize:10,
      total:0,

      windowWidth:window.screen.width
    }
  },
  created() {
  	this.getCoachList()
    this.getStudentList()
  },
  methods:{
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getStudentList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getStudentList()
    },


    toDetail(index,index2){
      this.dialogVisible = true
      this.curDetail = this.photoList[index].data[index2]
      console.log(index,index2,this.curDetail)
    },
    async getCoachList(){
      let res = await this.$http.get('/official/student/allStudent',{params:{studentType:0}})
      console.log(res)
      for(let i of res.data.data){
        if(typeof i.honorImage == 'string'){
          i.honorImage = i.honorImage.split(',')
        }
      }
      let obj = {
        type:'coach',
        data:res.data.data
      }
      // this.photoList.push(obj)
      this.$set(this.photoList,0,obj)
      // this.photoList[0] = obj
    },

    async getStudentList(){
      let res = await this.$http.get('/official/student/allStudent',{params:
        {
          studentType:1,
          pageSize:this.windowWidth>1200?this.pageSize:'',
          pageNum:this.windowWidth>1200?this.currentPage:''
        }})
      for(let i of res.data.data){
        if(typeof i.honorImage == 'string'){
          i.honorImage = i.honorImage.split(',')
        }
      }
      this.total = res.data.total
      if(this.photoList[1]){
        // this.photoList[1].data = this.photoList[1].data.concat(res.data.data)
        this.$set(this.photoList[1],'data',res.data.data)
      }else{
        let obj = {
          type:'student',
          data:res.data.data
        }
        // this.photoList.push(obj)
        // this.photoList[1] = obj
        this.$set(this.photoList,1,obj)
        console.log(this.photoList)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.photo_wall{
  background-color: #DE2910;
  width: 100%;
  position: relative;
  min-height: 100vh;
  img{
    width: 206px;
    display: inline-block;
    box-sizing: border-box;
    // &:hover{
    //   transform: scale(2);
    //   transition: .5s;
    // }
  }

  .photo_wall_left{
    width: 80%;
    box-sizing: border-box;
    padding: 0 40px;
    h1{
      letter-spacing: .5em;
      font-size: 3em;
      color: white;
      margin: 0;
      padding: 2%;
    }
    span{
      writing-mode: vertical-lr;
      font-size: 24px;
      color: white;
      letter-spacing: 5px;
      text-align: start;
      font-weight: bolder;
      display: block;
      width: 70px;
      float: left;
    }
    .photo_wall_left_row{
      margin-bottom: 10px;
      .photo_wall_left_row{
        text-align: left;
        padding-left: 70px;
        .photo_wall_left_row_item{
          position: relative;
          display: inline-block;
          background-color: #EEEEEF;
          margin-bottom: 10px;
          margin-right: 10px;
          font-size: 0;
          border-radius: 5px;
          .photo_wall_left_row_item_bottom{
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            background-color: #EEEEEF;
            border-radius: 5px;
            .el-button{
              &:nth-child(2){
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
          .photo_wall_left_row_item_qrCode{
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba($color: #000000, $alpha: .5);
            border-radius: 5px;
            img{
              width: 60%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
            }
          }
        }
      }
    }
  }
  .photo_wall_right{
     width: 20%;
     position: absolute;
     right: 0;
     top: 0;
     h1{
       letter-spacing: 5px;
       color: white;
     }
     img{

       &:first-child{
         width: 100%;
         border: 10px solid #F08619;
         border-right: none;
         box-sizing: border-box;
         &:hover{
           left: -50%;
           // transform: translate();
         }
       }
       &:last-child{
         width: 60%;
       }

     }
  }

  .hover{
    &:hover{
      transform: scale(2);
      transition: .5s;
      z-index: 999;
    }
  }

  .photo_wall_left_row_item_bottom_hover{
    opacity: 1 !important;
  }

  .photo_wall_left_row_item_qrCode_hover{
    display: block !important;
  }

  @media screen and (min-width:1200px) {
    ::v-deep .el-dialog{
      height: 70vh;
      min-width: 1200px;
      width: 60%;
      .el-dialog__header{
        display: none;
      }
      .el-dialog__body {
        height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
        background-color: #29283E;
      }
    }
  }

  @media screen and (max-width:768px) {
    ::v-deep .el-dialog{
      width: 100vw;
      height: 70vh;
      .el-dialog__header{
        display: none;
      }
      .el-dialog__body {
        height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
        // background-color: #29283E;
        background-image: url('https://abc.xnrun.com/image/cover/58075BC1FEE34A91B06545C785894B2D-6-2.png');
        background-size: cover;
        background-position: center;
      }
    }
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #F08619;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover{
    background-color: #F08619;
    color: white;
  }
  ::v-deep .el-pagination__total{
    color: white;
  }

  ::v-deep .el-pagination__jump{
    color: white;
  }

  .el-pagination{
    padding: 30px 0
  }

  ::v-deep .el-pagination__sizes .el-input .el-input__inner:hover{
    border-color: #F08619;
  }


  .mobile{
    color: white;
    padding: 5vw;
    .items{
      // padding: 5vw;
      h3{
        text-align: left;
        margin-top: 5vw;
      }
      .item{
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        text-align: left;
        .photo_wall_left_row_item2{
          position: relative;
          display: inline-block;
          width: 48%;
          margin: 1%;
          .photo_wall_left_row_item_bottom2{
            display: flex;
            width: 100%;
            .button{
              width: 100%;
              background-color: #EEEEEF;
              color: black;
              border-radius: 5px;
              text-align: center;
            }
          }
        }
        .photo_wall_left_row_item_qrCode2{
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: .5);
          border-radius: 5px;
          img{
            width: 60%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
        img{
          width: 100%;
        }
      }
    }
    .photo_wall_left_row_item_qrCode_hover2{
      display: block !important;
    }
  }

  @media screen and (max-width:1200px) {
    ::v-deep .el-dialog__body{
      width: 100vw;

    }
  }

}
</style>
