<template>
  <div>
    <el-row class="main" type="flex" justify="center">
      <el-col :span="screenWidth>699?10:23">
        <div id="artcle-info">
          <h2 class="text-center"><strong>{{thisList.title}}</strong></h2>
          <!-- 描述：文章信息 -->
          <div class="text-center timeAndView">
            <span class="article-time">
              <i class="el-icon-time"></i>
              发表于：<span>{{thisList.time}}</span>
            </span>
            &nbsp;|&nbsp;
            <span class="article-views">
              <i class="el-icon-view"></i>
              阅读量：<span>{{thisList.likeNum}}</span>
            </span>
          </div>
          <!-- <p class="abstract">
            前言：swagger2功能非常强大，用自己的一句话概括：它是一个构建强大的RESTful API文档以及调试的框架。
          </p> -->
        </div>
        <hr />
        <div id="artcle-content" style="text-align: initial;">
          <div v-html="thisList.content"></div>
        </div>
        <div v-if="screenWidth<699" class="item">
          <friend></friend>
        </div>
        <div id="statement" style="text-align: initial;width: 100%;">
          <div class="item">文章作者：小聂跑法</div>
          <div class="item">官网地址：
            <a href="https://www.xnrun.com/">https://www.xnrun.com</a>
          </div>
          <div class="item">版本声明：本站所有文章除特别声明外,转载请注明出处!</div>
        </div>
      </el-col>
      <el-col :span="screenWidth>699?4:0" class="hidden-sm-and-down" id="side">
        <div class="item">
          <tag></tag>
        </div>
        <div class="item">
          <friend></friend>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
    import tag from '../../components/tag'
    import friend from '../../components/friend'
  export default {
    name: 'article',
    components: {
      tag,
      friend
    },
    data() {
      return {
        screenWidth: document.body.clientWidth, // 屏幕宽
        screeHeight: document.body.clientHeight, // 屏幕高
        thisList: {},
        id: ''
      }
    },
    created() {

    },
    mounted: function() {
      if (this.$route.query.id != undefined) {
        this.id = this.$route.query.id
        console.log(this.id)
      }
      this.getCoachList();
    },
    methods: {
      //获取数据
      async getCoachList() {
        let res = await this.$http.get('/news/officialNews/selectById', {
          params: {
            id: this.id,
          }
        })
        console.log('数据', res.data.data);
        res.data.data.content = res.data.data.content.replace(
          /<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
          '<video style="width: 100%;" controls><source ');
        res.data.data.content = res.data.data.content.replace(/<\/iframe>/g, ' type="video/mp4"></video> ');
        res.data.data.content = res.data.data.content.replace(/<img/g,
          '<img style="width: auto;height: auto; max-width: 100%;max-height: 100%;" ');
        // res.data.data.content = res.data.data.content.replace(/.mp4/g, '.mp4?autoplay=false ');
        this.thisList = res.data.data;
      }
    }
  }
</script>

<style scoped>
  #side .item {
    margin-bottom: 30px;
  }

  .art-item {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  #artcle-info {
    padding: 20px;
    background-color: rgba(255, 194, 52, 0.6);
    margin-bottom: 40px;
  }

  #artcle-info .abstract {
    color: #ffffff;
    border-left: 3px solid #F56C6C;
    padding: 10px;
    background-color: rgba(126, 129, 135, 0.3);
  }

  #artcle-info .timeAndView {
    padding: 20px;
    line-height: 30px;
    font-size: 16px;
    color: #ffffff;
  }

  pre.has {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
  }

  img.has {
    width: 100%;
  }

  #statement {
    border-left: 3px solid #F56C6C;
    padding: 20px;
    background-color: #EBEEF5;
  }
</style>
