<template>
  <div class="home">
    <el-row id="artList"  type="flex" justify="space-around">
      <el-col :span="screenWidth>699?18:23">

      <el-col :span="screenWidth>699?16:23">
        <el-row class="art-item" v-for="(item,index) in dataList" :key="index">
          <el-card shadow="hover">
            <el-row class="art-info d-flex align-items-center justify-content-start">
            </el-row>
            <el-row class="art-body">
              <div class="side-img hidden-sm-and-down"><img class="art-banner" :src="item.titileImg"></div>
              <div class="side-abstract">
                <div class="titletext">
                  <router-link :to="{ path:'/article', query: { id: item.uuid }}" target="_blank" class="art-title">
                    {{item.title}}</router-link>
                </div>
                <div v-html="item.title" class="fwbys"
                  style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">
                </div>
                <div class="art-more">
                  <router-link class="" :to="{ path:'/article', query: { id: item.uuid }}" target="_blank" >
                    <el-button size="medium" plain>查看详情</el-button>
                  </router-link>
                  <!-- <div class="art-time"></div> -->
                  <!--<div class="d-flex align-items-center"><img class="tag" src="../../assets/tag.png" />：
                    <el-tag size="mini">swagger2</el-tag>
                  </div> -->
                  <div class="view"><i class="el-icon-view"></i> {{item.likeNum}} <text style="margin-left: 2%;">
                    </text><i class="el-icon-time"></i>：{{item.time}}</div>
                </div>
              </div>
            </el-row>
          </el-card>
          <img v-if="index<2" class="star" src="../../assets/star.png" />
        </el-row>
        <div class="block pagination" :style="{width:screenWidth+'px'}">
          <el-pagination style="width: 20%;font-size: 0.75rem;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[5,10,15, 30, 50, 100]" :page-size="pageSize"
            :layout="screenWidth<701?'total, sizes, prev, pager, next':'total, sizes, prev, pager, next, jumper'" :total="total">
          </el-pagination>
        </div>
        <div v-if="screenWidth<699" class="item">
          <friend></friend>
        </div>
      </el-col>

      <el-col :span="screenWidth>699?4:0" class="hidden-sm-and-down" id="side">
        <div class="item">
          <tag></tag>
        </div>
        <div class="item">
          <friend></friend>
        </div>
      </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import friend from '../../components/friend'
  import tag from '../../components/tag'
  export default {
    name: 'home',
    components: {
      friend,
      tag
    },
    data() {
      return {
        screenWidth: document.body.clientWidth, // 屏幕宽
        screeHeight: document.body.clientHeight, // 屏幕高
        dataList: [],
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    },
    created() {
      this.getCoachList()
      // this.getStudentList()
    },
    methods: {
      handleSizeChange(val) {
        this.pageSize = val;
        this.getCoachList();
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getCoachList();
        console.log(`当前页: ${val}`);
      },
      //获取数据
      async getCoachList() {
        let res = await this.$http.get('/news/officialNews/getNews', {
          params: {
            title: '',
            pageSize: this.pageSize,
            pageNum: this.currentPage,
            content: ''
          }
        })
        console.log('数据', res.data.data);
        console.log('数据', res.data.data);
        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].content = res.data.data[i].content.replace(
            /<iframe class="ql-video" frameborder="0" allowfullscreen="true"/g,
            '<video style="width: 100%;" controls><source ');
          res.data.data[i].content = res.data.data[i].content.replace(/<\/iframe>/g, ' type="video/mp4"></video> ');
        }
        this.dataList = res.data.data;
        this.total = res.data.total;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #side .item {
    margin-bottom: 30px;
  }

  .art-item {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .art-item .star {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
  }

  img.tag {
    width: 16px;
    height: 16px;
  }

  .art-title {
    color: #000;
    border-left: 3px solid #F56C6C;
    padding-left: 5px;
    cursor: pointer;
  }

  .art-title:hover {
    padding-left: 10px;
    color: #409EFF;
  }

  .art-time {
    margin-right: 20px;
  }

  .art-body {
    display: flex;
    padding: 5px 0;
  }

  .side-img {
    height: 150px;
    width: 270px;
    overflow: hidden;
    margin-right: 10px;
  }

  img.art-banner {
    @media screen and (max-width: 1200px) {
      width: 100%;
      height: unset;

    }

    width: 100%;
    height: 100%;
    transition: all 0.6s;
  }

  img.art-banner:hover {
    transform: scale(1.4);
  }

  .side-abstract {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .titletext {
      font-size: 33px;
      text-align: initial;
      font-weight: 800;
      @media screen and (max-width: 700px) {
        font-size: 16px;
        height: unset;

      }
    }

    .fwbys {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      font-size: 18px;
      @media screen and (max-width: 700px) {
        font-size: 4px;
        height: unset;

      }
    }

  }

  .art-abstract {
    flex: 1;
    color: #aaa;
  }

  .art-more {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 700px) {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .art-more .view {
    color: #aaa;
  }

  h5 {
    font-size: 36rpx;
  }

  .pagination {
    width: 100%;
    background-color: #F9F9F9;
  }
</style>
