<template>
  <div>
    <el-row class="main" type="flex" justify="center">
      <el-col :span="16">
        <div id="artcle-info">
          <h2 class="text-center" style="color: #FFFFFF;"><strong>{{thisList.title}}</strong></h2>
          <!-- 描述：文章信息 -->
          <div class="text-center timeAndView">
            <span class="article-time">
              <i class="el-icon-time"></i>
              发布时间：<span>{{thisList.lrrq}}</span>
            </span>
            <!-- &nbsp;|&nbsp; -->
            <!-- <span class="article-views">
              <i class="el-icon-view"></i>
              阅读量：<span>{{thisList.likeNum}}</span>
            </span> -->
          </div>
          <!-- <p class="abstract">
            前言：swagger2功能非常强大，用自己的一句话概括：它是一个构建强大的RESTful API文档以及调试的框架。
          </p> -->
        </div>
        <hr />
        <div id="artcle-content" style="text-align: initial;">
          <div v-html="thisList.content"></div>
        </div>
        <div id="statement" style="text-align: initial;">
          <div class="item">发布公告者：小聂跑法官方</div>
          <div class="item">官网地址：
            <a href="https://www.xnrun.com/">https://www.xnrun.com</a>
          </div>
          <div class="item">声明：最终解释权归小聂跑法官方所有!</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'article',
    components: {},
    data() {
      return {
        thisList: {},
        id: ''
      }
    },
    created() {

    },
    mounted: function() {
      if (this.$route.query.id != undefined) {
        this.id = this.$route.query.id
        console.log(this.id)
      }
      this.getCoachList();
    },
    methods: {
      //获取数据
      async getCoachList() {
        let res = await this.$http.get('/affiche/affiche/selectById', {
          params: {
            id: this.id,
          }
        })
        console.log('数据', res.data.data);
        res.data.data.content = res.data.data.content.replace(/<iframe/g,
          '<iframe style="width: 100%;height: 400px;" ');
        res.data.data.content = res.data.data.content.replace(/<img/g,
          '<img style=" max-width: 100%;max-height: 100%;" ');
        this.thisList = res.data.data;
      }
    }
  }
</script>

<style scoped>
  #artcle-info {
    padding: 20px;
    background-color: rgba(255, 31, 15, 1);
    margin-bottom: 40px;
  }

  #artcle-info .abstract {
    color: #ffffff;
    border-left: 3px solid #F56C6C;
    padding: 10px;
    background-color: rgba(126, 129, 135, 0.3);
  }

  #artcle-info .timeAndView {
    padding: 20px;
    line-height: 30px;
    font-size: 16px;
    color: #ffffff;
  }

  pre.has {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
  }

  img.has {
    width: 100%;
  }

  #statement {
    border-left: 3px solid #F56C6C;
    padding: 20px;
    background-color: #EBEEF5;
  }
</style>
